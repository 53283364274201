import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/Breadcrumb/actions"
import React, {  useEffect, useState } from "react"
const { forwardRef, useRef, useImperativeHandle } = React;
import {
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"

const UserModal = forwardRef((props, ref)  => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState("")
  const [group, setGroup] = useState("")
  const [selectedGender, setSelectedGender] = useState(null)
  const [selInstitutions, setSelInstitutions] = useState(null)
  const [selGroups, setSelGroups] = useState(null)

  const [groups, setGroups] = useState([])
  const [institutions, setInstitutions] = useState([])

  const [userData, setUserObject] = useState({
    firstname: "",
    lastname: "",
    surname: "",
    gender: "",
    idNumber: "",
    email: "",
    phoneNumber: "",
    tenantId: 0,
    groupId: 0,
  })



  const optionGender = [ {
      options: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
    },
  ]

  /**
   * Add method to request Find all User groups
   */
  useEffect(() => {
    // if (data.length > 1) {
    //   return
    // }
    axios
      .get(config.url.API_URL + "/configs/user-configs")
      .then(res => {
        console.log(res)
        const data = res.data
        if (data["status"] === "00") {
          setInstitutions(data.data.institutions)
          setGroups(data.data.groups)
        }
      })
      .catch(err => {
        if (null !== err.response.status && err.response.status === 401) {
          console.log("STATUS " + err.response.status)
        }
      })
  }, [])


  useImperativeHandle(ref, () => ({
    postData() {
      postUser()
    }
  }));


  const postUser = () => {
    console.log(userData)

    axios
      .post(config.url.API_URL + "/users/", userData)
      .then(res => {
        const response = res.data
        if (response['status'] === '00') {
          // console.log(response.data['message'])
          props.parentCallback(response['message']);
        } else {
          let message = response['message']
          // setErrMsg(message);
          // window.setTimeout(() => { setErrVisible(true)}, 1)
          // // console.log(message)
          console.log(response)
        }
      })
      .catch(err => {
        // props.logoutUser(props.history)
        // if (null !== err.response.status && err.response.status === 401) {
          console.log("STATUS " + err)
        // }
      })
  }


  return (
    <div>
      <div className="mt-0">
        <AvForm className="needs-validation" autocomplete="off">
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrVisible(false)}>
            {" "}
            {errMsg}{" "}
          </Alert>

          <div className="row mb-4">
            <label
              htmlFor="validationFirstName"
              className="col-sm-3 col-form-label" >
              First name
            </label>
            <div className="col-sm-5">
              <AvField
                name="firstName"
                placeholder="Enter first name"
                type="text"
                errorMessage="First name is required"
                className="form-control"
                onChange ={(e) => {
                  userData.firstname = e.target.value
                }}
                value={userData.firstname}
                validate={{ required: { value: true } }}
                id="validationFirstName"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationMidName"
              className="col-sm-3 col-form-label" >
              Middle Name
            </label>
            <div className="col-sm-5">
              <AvField
                name="middleName"
                placeholder="Enter middle name"
                type="text"
                errorMessage="Middle name is required"
                className="form-control"
                value={userData.lastname}
                onChange ={(e) => {
                  userData.lastname = e.target.value
                }}
                validate={{ required: { value: true } }}
                id="validationMidName"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationLstName"
              className="col-sm-3 col-form-label">
              Last Name
            </label>
            <div className="col-sm-5">
              <AvField
                name="surname"
                placeholder="Enter Last name"
                type="text"
                errorMessage="Last name is required"
                className="form-control"
                value={userData.surname}
                onChange ={(e) => {
                  userData.surname = e.target.value
                }}
                validate={{ required: { value: true } }}
                id="validationLstName" />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationIdNumber"
              className="col-sm-3 col-form-label" >
              Identification Number
            </label>
            <div className="col-sm-5">
              <AvField
                name="idNumber"
                placeholder="Enter Identification Number"
                type="number"
                errorMessage="Identification Number is required"
                className="form-control"
                value={userData.idNumber}
                onChange ={(e) => {
                  userData.idNumber = e.target.value
                }}
                validate={{ required: { value: true } }}
                id="validationIdNumber"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationEmail"
              className="col-sm-3 col-form-label">
              Email
            </label>
            <div className="col-sm-5">
              <AvField
                name="email"
                placeholder="Enter email"
                type="text"
                errorMessage="Email is required"
                className="form-control"
                value={userData.email}
                onChange ={(e) => {
                  userData.email = e.target.value
                }}
                validate={{ required: {value: true }}}
                id="validationEmail"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationPhoneNo"
              className="col-sm-3 col-form-label" >
              Mobile Number
            </label>
            <div className="col-sm-5">
              <AvField
                name="phoneNumber"
                placeholder="Enter mobile number"
                type="text"
                errorMessage="Phone number is required"
                className="form-control"
                value={userData.phoneNumber}
                onChange ={(e) => {
                  userData.phoneNumber = e.target.value
                }}
                validate={{ required: { value: true } }}
                id="validationPhoneNo"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationGender"
              className="col-sm-3 col-form-label" >
              Gender
            </label>
            <div className="col-sm-5">
              <Select
                value={selectedGender}
                onChange={e => {
                  setSelectedGender(e)
                  userData.gender = e.value
                }}
                options={optionGender}
                classNamePrefix="select2-selection"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationInstitution"
              className="col-sm-3 col-form-label">
              Institution
            </label>
            <div className="col-sm-5">
              <Select
                value={selInstitutions}
                onChange={e => {
                  setSelInstitutions(e)
                  userData.tenantId  = e.value
                }}
                options={
                  institutions.length > 0 &&
                  institutions.map((institution, i) => {
                    return { value: institution.id, label: institution.name }
                  })
                }
                classNamePrefix="select2-selection"
              />
            </div>
          </div>

          <div className="row mb-4">
            <label
              htmlFor="validationGender"
              className="col-sm-3 col-form-label">
              User Group
            </label>
            <div className="col-sm-5">
              <Select
                value={selGroups}
                onChange={e => {
                  setSelGroups(e)
                  userData.groupId  = e.value
                }}
                options={
                  groups.length > 0 && groups.map((group, i) => {
                    return { value: group.id, label: group.name }
                  })
                }
                classNamePrefix="select2-selection"
              />
            </div>
          </div>
        </AvForm>
      </div>
    </div>
  )
});

export default UserModal
