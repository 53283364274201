import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
// import authSaga from "./auth/login/saga"
import AuthSaga from  "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import UsersSaga from "./dashboard/user-mngmt/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    // UsersSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(calendarSaga),
  ])
}
