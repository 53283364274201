import{
  CREATE_USER,CREATE_SUCCESS, USERS, USERS_SUCCESS, USERS_BY_ID, USERS_BY_ID_SUCCESS,
  DELETE_USER, DELETE_USER_SUCCESS,API_ERROR
} from "./actionTypes"


export const createUsers = (user, history) => {
  return {
    type: CREATE_USER,
    payload: { user, history },
  }
}


export const createSuccess = project => {
  return {
    type: CREATE_SUCCESS,
    payload: project,
  }
}


export const users = () => {
  return {
    type: USERS,
  }
}


export const usersSuccess = projects => {
  return {
    type: USERS_SUCCESS,
    payload: projects
  }
}


export const userById = user => {
  return {
    type: USERS_BY_ID,
    payload: user,
  }
}


export const userByIdSuccess = user => {
  return {
    type: USERS_BY_ID_SUCCESS,
    payload: user
  }
}


export const deleteById = user => {
  return {
    type: DELETE_USER,
    payload: user,
  }
}


export const deleteByIdSuccess = user => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: user
  }
}


export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}