// actions
import React, { Component, useEffect, useRef, useState } from "react"
import { Link, Redirect, withRouter } from "react-router-dom"
import { config } from "../../store/config/BaseUrl"
import axios from "axios"
import NewProjectModal from "../modal/NewProjectModal"
import {
  apiError,
  loginUser,
  setBreadcrumbItems,
  users,
} from "../../store/actions"

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
  Label,
  Modal,
  Button,
} from "reactstrap"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Action to copy breadcrumb items from local state to redux state

import "../../assets/scss/datatables.scss"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import UserModal from "../modal/UserModal"
import UserGroupModal from "../modal/UserGroupModal"

const Users = props => {
  const userModal = useRef()

  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [confirm_alert, setConfirmAlert] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modal_standard, setModalStandard] = useState(false)

  const [successMsg, setSuccessMsg] = useState("")
  const [dynamic_title, setDynamicTitle] = useState("")
  const [dynamic_description, setDynamicTitleDesc] = useState("")

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Users", link: "/users" },
  ]

  const [data, setData] = useState([])

  useEffect(() => {
    props.setBreadcrumbItems("Users", breadcrumbItems)
  })

  const tog_standard = prevState => {
    setModalStandard(!prevState.modal_standard)
  }

  const setsuccess_msg = type => {
    window.setTimeout(() => {
      this.setState({ showSweetAlert: false })
    }, 1)
  }

  const handleCallBack = modalData =>{
    if(modalData){
      setDynamicTitleDesc(modalData)
      window.setTimeout(() => {
        setShowSweetAlert(true)
      }, 1)
    }
    setModalStandard(false)
  }

  const set_confirm_alert = show => {
    var isShow = false
    if (show) {
      isShow = true
    }
    window.setTimeout(() => {
      setConfirmAlert(isShow)
    }, 1)
  }


  const setsuccess_dlg = show => {
    window.setTimeout(() => {
      setShowSweetAlert(true)
      setDynamicTitle('Deleted')
      setDynamicTitleDesc('Your file has been deleted.')
      setDynamicTitle()
    }, 1)
  }


  const deleteProject = project => {
    //TODO add a method to delete project
    // window.setTimeout(() => { this.setState({ confirm_alert: true }) }, 1)
    console.log(
      "Are you sure you want to delete " +
        project["name"] +
        " Project" +
        JSON.stringify(project)
    )
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    axios
      .get(config.url.API_URL + "/users/")
      .then(res => {
        const users = res.data.data
        setData(users)
        console.log(users)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        // if (null != err.response.status && err.response.status === 401) {
        //   console.log("STATUS " + err.response.status)
        props.history.push("/login")
        // }
      })
  }, [])

  return (
    <React.Fragment>
      {showSweetAlert ? (
        <SweetAlert
          title={dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            window.setTimeout(() => {
              setShowSweetAlert(false)
            }, 1)
          }} >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          confirmButtonText="Yes, delete it!"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
          }}
          onCancel={() => confirm_alert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Button
                  type="button"
                  onClick={() => setModalStandard(!false)}
                  color="primary"
                  className="btn btn-success mb-2"
                >
                  New User
                </Button>

                <Modal
                  size="lg"
                  isOpen={modal_standard}
                  toggle={tog_standard}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      Create User
                    </h5>
                  </div>
                  <div className="modal-body">
                    <UserModal
                      ref={userModal}
                      parentCallback={handleCallBack}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={() => setModalStandard(false)}
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={() => userModal.current.postData()}
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Save changes
                    </button>
                  </div>
                </Modal>

                <div className="table-responsive mt-3">
                  <Table
                    className="table-centered datatable dt-responsive nowrap "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "20px" }}>
                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customercheck"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customercheck"
                            >
                              &nbsp;
                            </Label>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Institution</th>
                        <th>active</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((users, key) => (
                        <tr key={key}>
                          <td>
                            <div className="ustom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={"customercheck" + key}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={"customercheck" + key}
                              >
                                &nbsp;
                              </Label>
                            </div>
                          </td>

                          <td>{users.id}</td>
                          <td>{users.email}</td>
                          <td>
                            {users.firstname +
                              " " +
                              users.lastname +
                              " " +
                              users.surname}
                          </td>
                          <td>{users.phoneNumber}</td>
                          <td>{users.institution}</td>
                          <td>{users.active}</td>
                          <td>
                            <Link
                              to="#"
                              className="mr-3 text-primary"
                              id={"edit" + key}
                              onClick={() => {
                                tog_standard()
                                // project = projects
                              }}
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"edit" + key}
                              placement="top"
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Link
                              to="#"
                              className="text-danger"
                              id={"delete" + key}
                              onClick={() => {
                                setconfirm_alert(projects)
                              }}
                            >
                              {" "}
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"delete" + key}
                              placement="top"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.User
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { users, apiError, setBreadcrumbItems })(Users)
)

Users.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  users: PropTypes.func,
}
