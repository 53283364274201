import LocalStorageService from "../store/LocalStorageService";
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";


const localStorageService = LocalStorageService.getService()

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {

    if (!config.url.includes('/auth/authenticate')) {

        let info = localStorage.getItem("info")
        let JSONObject = JSON.parse(info);
        let token = JSONObject['token'];

        console.log(token);
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    console.log(`[request] [${JSON.stringify(config)}]`);
    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[request error] [${JSON.stringify(error)}]`);

    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {

    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};
