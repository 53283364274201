import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Alert, Label } from "reactstrap"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"
import Select from "react-select"
const { forwardRef, useRef, useImperativeHandle } = React;

const UserGroupModal = forwardRef((props, ref) => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState("")
  const [group, setGroup] = useState("")

  const [change, setChange] = useState(true)
  const [selectedMulti, setSelectedMulti] = useState([])
  const [permissions, setPermissions] = useState([])

  function handleMulti(selectedItem) {
    setSelectedMulti(selectedItem)
    console.log(selectedMulti)
  }


  useImperativeHandle(ref, () => ({
    postData() {
      postGroup()
    }
  }));


  const postGroup = () => {
    let name = document.getElementById("validationName").value;
    let userGrp = {
      name : name,
      permissions: selectedMulti.map((permission, i) => {
        return { code: permission.value}
      })
    }

    axios
      .post(config.url.API_URL + "/auth/auth-group", userGrp)
      .then(res => {
        const response = res.data

        if (response['status'] === '00') {
          console.log(response.data['message'])
          // this.props.parentCallback(true);
        } else {
          let message = response['message']
          setErrMsg(message);
          window.setTimeout(() => { setErrVisible(true)}, 1)
          // console.log(message)
          console.log(response)
        }
      })
      .catch(err => {
        // props.logoutUser(props.history)
        // if (null !== err.response.status && err.response.status === 401) {
          console.log("STATUS " + err)
        // }
      })
  }


  useEffect(() => {
    axios
      .get(config.url.API_URL + "/auth/permissions")
      .then(res => {
        const permissions = res.data.data
        setPermissions(permissions)
        console.log(permissions)
      })
      .catch(err => {
        // if (null !== err.response.status && err.response.status === 401) {
          console.log("STATUS " + err)
        // }
      })
  }, [])

  return (
    <div>
      <div className="mt-0">
        <AvForm className="needs-validation">
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrVisible(false)} >
            {" "}
            {errMsg}{" "}
          </Alert>

          <div className="mb-3">
            <Label htmlFor="validationName">First name</Label>
            <AvField
              name="name"
              placeholder="Enter Group name"
              type="text"
              errorMessage="Group Name"
              className="form-control"
              value={group.name}
              validate={{ required: { value: true } }}
              id="validationName"
            />
          </div>
          <div className="mb-3">
            <label className="control-label"> Select Permissions </label>
            <Select
              value={selectedMulti}
              isMulti={true}
              onChange={e => {
                handleMulti(e)
              }}
              classNamePrefix="select2-selection"
              options = {
                permissions.length > 0 && permissions.map((permission, i) => {
                  return { value: permission.code, label: permission.name }
                })
              }
            />
          </div>
        </AvForm>
      </div>
    </div>
  )
});
export default UserGroupModal
