import axios from 'axios'
import Dashboard from 'pages/Dashboard';
import { config } from "../config/BaseUrl";

const instance = axios.create();

class DashboardService {

    constructor() {
    }

    getProjects = (url) => {
        return instance.get(config.url.API_URL + url
        ).then(res => {
            if (response.data['status'] === '00') {
                return response.data;
            } else {
                throw response.data['message'];
            }
        }).catch(err => {
            throw err;
        })
    }


    fetchUsers = (url) => {
        return instance.get(config.url.API_URL + url
        ).then(res => {
            if (response.data['status'] === '00') {
                return response.data;
            } else {
                throw response.data['message'];
            }
        }).catch(err => {
            throw err;
        })
    }


}


let _restService = null;

const getService = () => {

    if (!_restService) {
        _restService = new DashboardService();
    }
    return _restService;
};

export { getService };
