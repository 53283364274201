import {
  CREATE_USER,
  CREATE_SUCCESS,
  USERS, USERS_SUCCESS
} from "./actionTypes"


const INIT_STATE = {
  users: [],
  error: {},
  loading: false,
}


const User = (state = INIT_STATE, action) => {

  switch (action.type) {

    case CREATE_USER:
      return {
        ...state,
        user: action.payload,
      }

    case CREATE_SUCCESS:
      return {
        ...state,
        user: action.payload,
      }

    case USERS:
      return {
        ...state,
        users: action.payload,
      }

    case USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    default:
      return state
  }
}

export default User
