import React, { useEffect, useRef, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions"
import axios from "axios"
import { config } from "../../../store/config/BaseUrl"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import UserGroupModal from "../../modal/UserGroupModal"


const UserGroups = props => {

  const userGroupModal = useRef()
  const [data, setData] = useState([])
  const [modalData, setModalData] = useState([])
  const [postData, setPostData] = useState(false)

  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [confirm_alert, setConfirmAlert] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modal_standard, setModalStandard] = useState(false)

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Users", link: "/users" },
  ]


  const tog_standard = prevState => {
    // setModalStandard(!prevState)
    // removeBodyCss()
  }


  const handleCallback = childData => {
    if (childData) {
      window.setTimeout(() => {
        this.setState({ showSweetAlert: true })
      }, 1)
      this.tog_standard()
    }
  }


  const saveChanges = () => {
    // projectModal.current.postData()
    // console.log("Save Changes")
    // setData()
    setPostData(true)
  }


  useEffect(() => {
    props.setBreadcrumbItems("Users", breadcrumbItems)
  },[])


  /**
   * Add method to request Find all User groups
   */
  useEffect(() => {
    if(data .length > 1){
      return
    }
    axios
      .get(config.url.API_URL + "/auth/auth-groups")
      .then(res => {
        const data = res.data.data
        setData(data)
        console.log(data)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        // if (null !== err.response.status && err.response.status === 401) {
          console.log("STATUS " + err)
          // this.props.history.push("/login")
        // }
      })
  },[])

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Button
                  type="button"
                  onClick={()=> setModalStandard(!false)}
                  color="primary"
                  className="btn btn-success mb-2">
                  New User Group
                </Button>

                <Modal isOpen={modal_standard} toggle={tog_standard}>
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      Create User Group
                    </h5>
                  </div>
                  <div className="modal-body">
                    <UserGroupModal
                      ref={userGroupModal}
                      parentCallback={handleCallback}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={()=> setModalStandard(false)}
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      onClick={() => userGroupModal.current.postData()}
                      type="button"
                      className="btn btn-primary waves-effect waves-light">
                      Save changes
                    </button>
                  </div>
                </Modal>

                <div className="table-responsive mt-3">
                  <Table
                    className="table-centered datatable dt-responsive nowrap "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "20px" }}>
                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customercheck"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customercheck"
                            >
                              &nbsp;
                            </Label>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Created By</th>
                        <th>active</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((groupItem, key) => (
                        <tr key={key}>
                          <td>
                            <div className="ustom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={"customercheck" + key}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={"customercheck" + key}>
                                &nbsp;
                              </Label>
                            </div>
                          </td>

                          <td>{groupItem.id}</td>
                          <td>{groupItem.name}</td>
                          <td>{groupItem.createdBy}</td>
                          <td>{groupItem.flag}</td>
                          <td>
                            <Link
                              to="#"
                              className="mr-3 text-primary"
                              id={"edit" + key}
                              onClick={() => {
                                tog_standard()
                                // project = projects
                              }}
                            >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"edit" + key}
                              placement="top"
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Link
                              to="#"
                              className="text-danger"
                              id={"delete" + key}
                              onClick={() => {
                                setconfirm_alert(projects)
                              }}
                            >
                              {" "}
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"delete" + key}
                              placement="top">
                              Delete
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, { setBreadcrumbItems })(UserGroups))
