import axios from 'axios'
import { config } from "../config/BaseUrl";

const instance = axios.create();

class AuthService {

    constructor() {

    }

    loginUser = (url, user) => {

        return instance.post(config.url.API_URL + url, user,{
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            console.log(response.data)
            if (response.data['status'] === '00' || response.data['status'] === '101') {
                return response.data;
            } else {
                throw response.data['message'];
            }
        }).catch(error => {
            throw error;
        })
        // const params = new URLSearchParams();
        // params.append("username", email)
        // params.append("password", password)
        // params.append("grant_type", 'password')

        // return instance.post(config.url.API_URL + url, params, {
        //     headers: {
        //         'Authorization': 'Basic ' + btoa('app-client:app-secret'),
        //         'Content-type': 'application/x-www-form-urlencoded'
        //     },
        // }).then((response) => {
        //     if (response.data['status'] === '00') {
        //         return response.data;
        //     } else {
        //         throw response.data['message'];
        //     }
        // }).catch(err => {
        //     throw err;
        // });
    };


    registerUser = (url, data) => {
        console.log(data['fullname'])
        let res = data['fullname'].split(" ");
        data["firstName"] = res[0]
        data["lastName"] = res[1]
        data["phoneNumber"] = data['phone']

        const baseUrl = config.url.API_URL + url;
        return instance.post(baseUrl, data).then((response) => {
            console.log(response.data)
            if (response.data['status'] === '00') {
                return response.data;
            } else {
                throw response.data['message'];
            }
        }).catch(error => {
            if (error.status === 404){

            }
            throw error;
        })
    };


    _handleError(error) {
        // var errorCode = error.code;
        let errorMessage = error.message;
        return errorMessage;
    }
}


let _authService = null;
const getAuthService = () => {

    if (!_authService) {
        _authService = new AuthService();
    }
    return _authService;
};

export { getAuthService };
