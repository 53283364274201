import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { Row, Col, Card, Form, Alert, Label } from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

const UploadTerminals = forwardRef((props, ref) => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState(false)
  const [projects, setProjects] = useState([])
  const [configs, setConfigs] = useState([])
  const [terminal, setTerminal] = useState(props.terminal)
  const [selFiles, setSelFiles] = useState([])

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelFiles(files)
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    getProjectConfigs()
  }, [])

  useImperativeHandle(ref, () => ({
    postData() {
      postData()
    },
  }))

  function getProjectConfigs() {
    let url = config.url.API_URL + "/configs/projects"
    axios
      .get(url)
      .then(res => {
        let data = res.data.data
        setProjects(data.projects)
        setConfigs(data.configs)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        if (err.response.status === 401) {
          console.log("STATUS " + err.response.status)
          props.history.push("/login")
        }
      })
  }

  function postData() {
    console.log("Upload Terminals CSV File")

    if (!selFiles[0]) {
      // return Error select afile to proceed
      return
    }

    var file = selFiles[0]
    const formData = new FormData()

    formData.append("file", file)

    const headers = {
      headers: {
        "content-type": "multipart/form-data",
      },
    }

    var url = config.url.API_URL + "/terminals/upload"
    axios
      .post(url, formData, headers)
      .then(response => {
        console.log(response.data)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        if (err.response.status === 401) {
          console.log("STATUS " + err.response.status)
          props.history.push("/login")
        }
      })
  }

  return (
    <React.Fragment>
      <div>
        <AvForm>
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrMsg(false)}
          >
            {" "}
            {errMsg}{" "}
          </Alert>

          <div className="mb-3">
            <Label htmlFor="validationConfig">Select Project</Label>
            <Select
              id="select-projects"
              onChange={e => (terminal.projectId = e.value)}
              classNamePrefix="select2-selection"
              options={
                projects.length > 0 &&
                projects.map((item, i) => {
                  return { value: item.id, label: item.name }
                })
              }
            ></Select>
          </div>

          <div className="mb-3">
            <Label htmlFor="validationConfig">Select OS Type</Label>
            <Select
              onChange={e => (terminal.osTypeId = e.value)}
              classNamePrefix="select2-selection"
              options={
                configs.length > 0 &&
                configs.map((item, i) => {
                  return { value: item.id, label: item.name }
                })
              }
            ></Select>
          </div>

          <div className="mb-3">
            <Label htmlFor="validationModel">Model</Label>
            <AvField
              name="model"
              placeholder="Enter Model"
              type="text"
              errorMessage="Enter Model"
              className="form-control"
              value={terminal.name}
              validate={{ required: { value: true } }}
              onChange={e => {
                terminal.name = e.target.value
              }}
              id="validationModel"
            />
          </div>

          <div class="alert alert-success">
            <p class="padding-10">
              <h5>CSV File (Upload Formats):</h5>
            </p>
          </div>

          <Dropzone
            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                  </div>
                  <h4>Click or Drop CSV file to upload</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selFiles.map((f, i) => {
              return (
                <Card
                  className="mt-0 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>
        </AvForm>

        {/* <div className="text-center mt-4"> <Button color="primary" type="button" className="waves-effect waves-light">Send Files</Button>
                                        </div> */}
      </div>
    </React.Fragment>
  )
})

export default UploadTerminals
