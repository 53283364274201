

const LocalStorageService = (function () {

    var _service;
    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _set(key, value) {
        console.log(key)
        localStorage.setItem(key, value);
    }

    function _get(key) {
        console.log("obj")
        const obj = JSON.parse(localStorage.getItem("info"));

        return obj.key
    }

    function _clearStorage() {
        localStorage.clear()
    }

    return {
        getService: _getService,
        set: _set,
        getAccessToken: _get,
        clearToken: _clearStorage
    }
})();

export default LocalStorageService;
