import React, { Component, forwardRef, useImperativeHandle, useState } from "react"
import { Label, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"

const NewProjectModal = forwardRef((props, ref) => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState("")
  const [project, setProject] = useState(props.project)

  const onTrigger = event => {
    event.preventDefault()
  }


  useImperativeHandle(ref, () => ({
    postData() {
      postNewProject()
    },
  }))

  function postNewProject() {
    setErrVisible(false)

    // project({
    //   name: document.getElementById("validationName").value,
    //   supportedVersion: document.getElementById("validationVersion").value,
    //   description: document.getElementById("validationDesc").value,
    //   testUrl: document.getElementById("validationTestUrl").value,
    //   prodUrl: document.getElementById("validationProdUrl").value,
    // })
    // project.project.this.state.project.this.state.project.var
    const url = config.url.API_URL + "/projects/"
    console.log("URL" + url)

    console.log(JSON.stringify(project))

    axios
      .post(url, project)
      .then(response => {
        if (response.data["status"] === "00") {
          // console.log(response.data['message'])
          response.data
          props.parentCallback(response.data['message'])
        } else {
          setErrMsg(response.data["message"])
          setErrVisible(true)
          this.props.parentCallback(false)
          window.setTimeout(() => {
            setErrVisible(true)
          }, 1)

          throw message
        }
      })
      .catch(err => {
        // check if error is 401 log out user
        // console.log("STATUS " + err.response.status)
        throw err
      })
  }

  return (
    <div>
      <div className="mt-0">
        <AvForm className="needs-validation">
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrVisible(false)}>
            {" "}
            {errMsg}{" "}
          </Alert>

          <div className="mb-3">
            <Label htmlFor="validationName">First name</Label>
            <AvField
              name="name"
              placeholder="Enter project name"
              type="text"
              errorMessage="Enter Project Name"
              className="form-control"
              value={project.name}
              onChange ={(e) => {
                project.name = e.target.value
              }}
              validate={{ required: { value: true } }}
              id="validationName"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationVersion">Supported Version</Label>
            <AvField
              name="Supported versions"
              placeholder="Enter supported versions"
              type="text"
              value={project.suppoprtedVersion}
              onChange ={(e) => {
                project.supportedVersion = e.target.value
              }}
              errorMessage="Enter supported versions"
              className="form-control"
              validate={{ required: { value: true } }}
              id="validationVersion"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationDesc">Description</Label>
            <AvField
              name="description"
              placeholder="Enter project description"
              type="text"
              value={project.description}
              onChange ={(e) => {
                project.description = e.target.value
              }}
              errorMessage="Enter project description"
              className="form-control"
              validate={{ required: { value: true } }}
              id="validationDesc"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationTestUrl">Test URL</Label>
            <AvField
              name="testUrl"
              placeholder="Enter test URL"
              type="text"
              errorMessage="Enter test URL"
              className="form-control"
              value={project.testUrl}
              onChange ={(e) => {
                project.testUrl = e.target.value
              }}
              validate={{ required: { value: true } }}
              id="validationTestUrl"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationProdUrl">Test URL</Label>
            <AvField
              name="prodUrl"
              value={project.prodUrl}
              onChange ={(e) => {
                project.prodUrl = e.target.value
              }}
              placeholder="Enter production URL"
              type="text"
              errorMessage="Enter production URL"
              className="form-control"
              validate={{ required: { value: true } }}
              id="validationProdUrl"
            />
          </div>
        </AvForm>
      </div>
    </div>
  )
})

export default NewProjectModal
