// actions
import React, { Component, useEffect, useRef, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { config } from "../../store/config/BaseUrl"
import axios from "axios"
import NewProjectModal from "../modal/NewProjectModal"
import { logoutUser } from "../../store/actions"

import {
  Container,
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
  Label,
  Modal,
  Button,
} from "reactstrap"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import "../../assets/scss/datatables.scss"

const Projects = props => {
  const projectModal = useRef()

  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [confirm_alert, setConfirmAlert] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modal_standard, setModalStandard] = useState(false)

  const [successMsg, setSuccessMsg] = useState("")
  const [dynamic_title, setDynamicTitle] = useState("")
  const [dynamic_description, setDynamicTitleDesc] = useState("")

  const [data, setData] = useState([])
  const [project, setProject] = useState({
    id: null,
    name: "",
    supportedVersion: "",
    description: "",
    testUrl: "",
    prodUrl: "",
  })

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Projects", link: "/projects" },
  ]

  const tog_standard = prevState => {
    setModalStandard(!prevState.modal_standard)
    removeBodyCss()
  }

  const setsuccess_msg = type => {
    window.setTimeout(() => {
      setShowSweetAlert(false)
    }, 1)
  }

  const setconfirm_alert = show => {
    var isShow = false
    if (show) {
      isShow = true
    }
    window.setTimeout(() => {
      setconfirm_alert(isShow)
    }, 1)
  }

  
  const setsuccess_dlg = show => {
    window.setTimeout(() => {
      setShowSweetAlert(true)
      setdynamic_title("Deleted")
      setdynamic_description("Your file has been deleted.")
    }, 1)
  }


  const handleCallback = childData => {
    if (childData) {
      setDynamicTitleDesc(childData)
      window.setTimeout(() => {
        setShowSweetAlert(true)
      }, 1)
      setModalStandard(false)
    }
  }


  const deleteProject = project => {
    //TODO add a method to delete project
    // window.setTimeout(() => { this.setState({ confirm_alert: true }) }, 1)
    console.log(
      "Are you sure you want to delete " + project["name"] +  " Project" + JSON.stringify(project)
    )
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  useEffect(() => {
    try {
      JSON.parse(localStorage.getItem("info"))
    } catch {
      console.log("Error")
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        /> )
    }

    axios
      .get(config.url.API_URL + "/projects/")
      .then(res => {
        const institutions = res.data.data
        setData(institutions)
        console.log(institutions)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        if (err.response.status === 401) {
          console.log("STATUS " + err.response.status)
          this.props.history.push("/login")
        }
      })
  }, [])

  return (
    <React.Fragment>
      {showSweetAlert ? (
        <SweetAlert
          title={dynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowSweetAlert(false)
          }} >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          confirmButtonText="Yes, delete it!"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
          }}
          onCancel={() => setconfirm_alert(false)}>
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Button
                  type="button"
                  onClick={() => {
                    setModalStandard(!false)
                  }}
                  color="primary"
                  className="btn btn-success mb-2" >
                  Add Project
                </Button>

                <Modal
                  isOpen={modal_standard}
                  toggle={tog_standard}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                      New project
                    </h5>
                  </div>
                  <div className="modal-body">
                    <NewProjectModal
                      ref={projectModal}
                      parentCallback={handleCallback}
                      project={project}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={() => {
                        setModalStandard(false)
                      }}
                      className="btn btn-secondary waves-effect"
                      data-dismiss="modal" >
                      Close
                    </button>
                    <button
                      onClick={()=> projectModal.current.postData()}
                      type="button"
                      className="btn btn-primary waves-effect waves-light" >
                      Save changes
                    </button>
                  </div>
                </Modal>

                <div className="table-responsive mt-3">
                  <Table
                    className="table-centered datatable dt-responsive nowrap "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "20px" }}>
                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customercheck"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customercheck"
                            >
                              &nbsp;
                            </Label>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Supported Versions</th>
                        <th>Description</th>
                        <th>Test Url</th>
                        <th>Production</th>
                        <th>active</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((projects, key) => (
                        <tr key={key}>
                          <td>
                            <div className="ustom-control custom-checkbox">
                              <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={"customercheck" + key}
                              />
                              <Label
                                className="custom-control-label"
                                htmlFor={"customercheck" + key} >
                                &nbsp;
                              </Label>
                            </div>
                          </td>
                          <td>{projects.id}</td>
                          <td>{projects.name}</td>
                          <td>{projects.supportedVersion}</td>
                          <td>{projects.description}</td>
                          <td>{projects.testUrl}</td>
                          <td>{projects.prodUrl}</td>
                          <td>{projects.active}</td>
                          <td>
                            <Link
                              to="#"
                              className="mr-3 text-primary"
                              id={"edit" + key}
                              onClick={() => {
                                tog_standard()
                                project = projects
                              }} >
                              <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"edit" + key}
                              placement="top" >
                              Edit
                            </UncontrolledTooltip>
                            <Link
                              to="#"
                              className="text-danger"
                              id={"delete" + key}
                              onClick={() => {
                                setconfirm_alert(projects)
                              }}>
                              {" "}
                              <i className="mdi mdi-trash-can font-size-18"></i>
                            </Link>
                            <UncontrolledTooltip
                              target={"delete" + key}
                              placement="top">
                              Delete
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Projects
