import React, { Component, useRef, useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
} from "reactstrap"
import classnames from "classnames"
import UploadTerminals from "pages/modal/UploadTerminal"
import NewTerminalModal from "pages/modal/NewTerminalModal"
import ActiveTerminalsTab from "pages/tabs/terminals/ActiveTerminalsTab"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/Breadcrumb/actions"

const Terminals = props => {
  const terminalModal = useRef()
  const uploadModal = useRef()

  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [confirm_alert, setConfirmAlert] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modal_upload, setModalUpload] = useState(false)
  const [modal_new, setModalUNew] = useState(false)
  const [activeTab, setActiveTab] = useState("1")

  const [dynamic_title, setDynamicTitle] = useState("")
  const [dynamic_description, setDynamicTitleDesc] = useState("")

  const [terminal, setTerminal] = useState({
    projectId: null,
    osType: null,
    name: "",
    serialNumber: "",
    osVersion: "",
    imei: "",
    active: false,
  })

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const tog_modal = type => {
    if (1 === type) {
      setModalUpload(!modal_upload)
    } else {
      setModalUNew(!modal_new)
    }
    removeBodyCss()
  }

  const onClick = type => {
    console.log("Test")
    if (!type) {
      return
    }
    if (2 === type) {
      terminalModal.current.postData()
    } else {
      uploadModal.current.postData()
    }
  }

  const handleCallback = childData => {
    if (childData) {
      window.setTimeout(() => {
        setShowSweetAlert(true)
      }, 1)
      tog_modal()
    }
  }

  const setsuccess_msg = type => {
    window.setTimeout(() => {
      setShowSweetAlert(false)
    }, 1)
  }

  return (
    <React.Fragment>
      <div>
        {showSweetAlert ? (
          <SweetAlert
            title={dynamic_title}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }} >
            {dynamic_description}
          </SweetAlert>
        ) : null}

        {confirm_alert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            confirmButtonText="Yes, delete it!"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setConfirmAlert(false)
              setsuccess_msg(true)
            }}
            onCancel={() => setConfirmAlert(false)} >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        <Modal isOpen={modal_new} toggle={tog_modal}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Add Terminal
            </h5>
          </div>

          <div className="modal-body">
            <NewTerminalModal
              ref={terminalModal}
              parentCallback={handleCallback}
              terminal={terminal} />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_modal(2)
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal" >
              Close
            </button>
            <button
              onClick={() => {
                onClick(2)
              }}
              type="button"
              className="btn btn-primary waves-effect waves-light" >
              Save changes
            </button>
          </div>
        </Modal>

        {/* Modal upload terminals */}
        <Modal size="l" isOpen={modal_upload} toggle={tog_modal}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Upload Terminal's
            </h5>
          </div>

          <div className="modal-body">
            <UploadTerminals
              ref={uploadModal}
              terminal={terminal}
              parentCallback={handleCallback}
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_modal(1)
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal" >
              Close
            </button>
            <button
              onClick={() => {
                onClick(1)
              }}
              type="button"
              className="btn btn-primary waves-effect waves-light" >
              Upload Files
            </button>
          </div>
        </Modal>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="button-items mb-1">
                  <Button
                    type="button"
                    onClick={() => {
                      tog_modal(2)
                    }}
                    color="primary"
                    className="btn btn-success mb-2"
                  >
                    Add Terminals
                  </Button>

                  <Button
                    type="button"
                    onClick={() => {
                      tog_modal(1)
                    }}
                    color="primary"
                    className="btn btn-success mb-2"
                  >
                    Upload
                  </Button>
                </div>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      Active
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                        // setActiveTab("2"),
                      })}
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      Edited
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3")
                      }}
                    >
                      Deactivated
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        toggle("4")
                      }}
                    >
                      In-Active
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <ActiveTerminalsTab />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0"></CardText>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0"></CardText>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <CardText className="mb-0"></CardText>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, { setBreadcrumbItems })(Terminals))
