import React, { Component, useState } from "react"
import {
  Label,
  Alert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"

import Select from "react-select"
const { forwardRef, useRef, useImperativeHandle } = React;

const NewConfigModal = forwardRef((props, ref) => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState(false)
  const [selConfig, setSelConfig] = useState(props.selConfig)
  const [allConfigs, setAllConfigs] = useState(props.configCat)

  useImperativeHandle(ref, () => ({
    postData() {
      postConfig()
    },
  }))

  const postConfig = () => {
    let configId = selConfig.configId
    if (!configId) {
      return
    }

    console.log("ConFig Selected!!  " + JSON.stringify(selConfig))
    // this.state.errVisible = false;
    let name = document.getElementById("validationName").value
    selConfig.name = name
    setSelConfig({
      name: name
    })

    var url = config.url.API_URL + "/configs/"

    axios
      .post(url, selConfig)
      .then(response => {
        if (response.data["status"] === "00") {
          // console.log(response.data['message'])
          response.data
          props.parentCallback(true)
        } else {
          let message = response.data["message"]
          this.state.errMsg = message
          this.state.errVisible = true
          // this.props.parentCallback(false);
          window.setTimeout(() => {
            this.setState({ errVisible: true })
          }, 1)
          // console.log(message)
          throw message
        }
      })
      .catch(err => {
        // check if error is 401 log out user
        // console.log("STATUS " + err.response.status)

        throw err
      })
  }

  function handleOnChange(e) {
    // console.log(e.value)
    setSelConfig({
      configId : e.value
    })

    console.log(e.value)
    // this.setState({ selConfig: { ...this.state.selConfig, configId: e.value } })

    // // this.props.parentCallback("hello");
    // console.log("Config ID Selected!!  " + JSON.stringify(this.state.selConfig))
    // // e.preventDefault();
  }


  return (
    <div>
      <div className="mt-0">
        <AvForm className="needs-validation">
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrVisible(false)}
          >
            {" "}
            {errMsg}{" "}
          </Alert>
          <div className="mb-3">
            <Label htmlFor="validationConfig">Select Config</Label>
            <Select
              onChange={handleOnChange}
              classNamePrefix="select2-selection"
              options={
                allConfigs.length > 0 &&
                allConfigs.map((item, i) => {
                  return { value: item.id, label: item.name }
                })
              }
            ></Select>
          </div>

          <div className="mb-3">
            <Label htmlFor="validationName">Config Name</Label>
            <AvField
              name="name"
              placeholder="Enter config name"
              type="text"
              value={selConfig.name}
              errorMessage="Enter config name"
              className="form-control"
              validate={{ required: { value: true } }}
              id="validationName"
            />
          </div>
        </AvForm>
      </div>
    </div>
  )
})

export default NewConfigModal
