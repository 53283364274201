import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"




// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Configs from "pages/Dashboard/Configs"
import Users from "../pages/Dashboard/Users"
import UserGroups from "../pages/Dashboard/users/UserGroups"

import Projects from "../pages/Dashboard/Projects"
import Terminals from "pages/Dashboard/Terminals"
import ResetPassword from "../pages/Authentication/ResetPassword"

import Error404 from "../pages/error/Error404"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/projects", component: Projects },
  { path: "/users", component: Users },
  { path: "/user-groups", component: UserGroups },
  { path: "/terminals", component: Terminals },
  { path: "/configs", component: Configs },

  // // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },

]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  {path: "/reset-password", component: ResetPassword},
  {path: "/notfound", component: Error404},
  // { path: "/register", component: Register },

  { path: "/error", exact: true, component: () => <Redirect to="/notfound" /> },
]

export { userRoutes, authRoutes }