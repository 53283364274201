import React, {
  Component,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import {
  Label,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  Alert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"
import Select from "react-select"
import { withRouter } from "react-router-dom"

const NewTerminalModal = forwardRef((props, ref) => {
  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState(false)
  const [projects, setProjects] = useState([])
  const [configs, setConfigs] = useState([])
  const [terminal, setTerminal] = useState(props.terminal)

  const onTrigger = event => {
    event.preventDefault()
  }

  useEffect(() => {
    getProjectConfigs()
  }, [])

  useImperativeHandle(ref, () => ({
    postData() {
      postData()
    },
  }))

  function getProjectConfigs() {
    let url = config.url.API_URL + "/configs/projects"
    axios
      .get(url)
      .then(res => {
        let data = res.data.data
        setProjects(data.projects)
        setConfigs(data.configs)
      })
      .catch(err => {
        // props.logoutUser(props.history)
        if (err.response.status === 401) {
          console.log("STATUS " + err.response.status)
          props.history.push("/login")
        }
      })
  }

  const handleOnChange = (e, type) => {
    if (1 === type) {
      terminal.projectId = e.value
    } else {
      terminal.osTypeId = e.value
    }
  }

  function postData() {
    //  this.state.errVisible = false;

    // if (null != projectId) {
    //   return //TODO handle validation
    // }

    // if (null != osType) {
    //   return //TODO handle validation
    // }

    // this.state.terminal.serialNumber =
    //   document.getElementById("validationSN").value
    // this.state.terminal.name =
    //   document.getElementById(
    //     "validationModel"
    //   ).value /*TODO Project ID pick from select */
    // this.state.terminal.osVersion = document.getElementById(
    //   "validationOSVersion"
    // ).value /* Select from list of projects */
    // this.state.terminal.imei = document.getElementById("validationImei").value

    // this.state.terminal.supportedVersion = document.getElementById("validationVersion").value

    var url = config.url.API_URL + "/terminals/"
    console.log("URL" + url)
    console.log(JSON.stringify(terminal))

    axios
      .post(url, new Array(terminal))
      .then(response => {
        if (response.data["status"] === "00") {
          response.data
          props.parentCallback(true)
        } else {
          let message = response.data["message"]
          setErrMsg(message)
          setErrVisible(true)
          props.parentCallback(false)
          window.setTimeout(() => {
            setErrVisible(true)
          }, 1)
          // console.log(message)
          throw message
        }
      })
      .catch(err => {
        // check if error is 401 log out user
        // console.log("STATUS " + err.response.status)
        // console.log("Error")
        throw err
      })
  }

  return (
    <div>
      <div className="mt-0">
        <AvForm className="needs-validation">
          <Alert
            color="danger"
            isOpen={errVisible}
            toggle={e => setErrMsg(false)}
          >
            {" "}
            {errMsg}{" "}
          </Alert>

          <div className="mb-3">
            <Label htmlFor="validationConfig">Select Project</Label>
            <Select
              id="select-projects"
              onChange={e => handleOnChange(e, 1)}
              classNamePrefix="select2-selection"
              options={
                projects.length > 0 &&
                projects.map((item, i) => {
                  return { value: item.id, label: item.name }
                })
              }
            ></Select>
          </div>

          <div className="mb-3">
            <Label htmlFor="validationConfig">Select OS Type</Label>
            <Select
              onChange={e => handleOnChange(e, 2)}
              classNamePrefix="select2-selection"
              options={
                configs.length > 0 &&
                configs.map((item, i) => {
                  return { value: item.id, label: item.name }
                })
              }
            ></Select>
          </div>

          <div className="mb-3">
            <Label htmlFor="validationModel">Model</Label>
            <AvField
              name="model"
              placeholder="Enter Model"
              type="text"
              errorMessage="Enter Model"
              className="form-control"
              value={terminal.name}
              validate={{ required: { value: true } }}
              onChange={e => {
                terminal.name = e.target.value
              }}
              id="validationModel"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationSN">Serial Number</Label>
            <AvField
              name="serialNumber"
              placeholder="Enter terminal Serial Number"
              type="text"
              value={terminal.serialNumber}
              errorMessage="Enter terminal Serial Number"
              className="form-control"
              validate={{ required: { value: true } }}
              onChange={e => {
                terminal.serialNumber = e.target.value
              }}
              id="validationSN"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationOSVersion">OS Version</Label>
            <AvField
              name="osVersion"
              placeholder="Enter OS Version"
              type="text"
              errorMessage="Enter OS Version"
              className="form-control"
              value={terminal.osVersion}
              validate={{ required: { value: true } }}
              onChange={e => {
                terminal.osVersion = e.target.value
              }}
              id="validationOSVersion"
            />
          </div>

          <div className="mb-3">
            <Label htmlFor="validationImei">IMEI Number</Label>
            <AvField
              name="imei"
              value={terminal.imei}
              onChange={e => {
                terminal.imei = e.target.value
              }}
              placeholder="Enter IMEI Number"
              type="text"
              className="form-control"
              id="validationImei"
            />
          </div>
        </AvForm>
      </div>
    </div>
  )
})

export default NewTerminalModal
