import { takeLatest, takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

// AUTH related methods
import { getAuthService } from "../AuthService"

const authService = getAuthService()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(
      authService.loginUser,
      "/auth/authenticate",
      user
    )
    // console.log(response)
    let info = {
      token: response["access_token"],
      firstName: response["data"]["firstName"],
      lastName: response["data"]["middleName"],
      email: response["data"]["email"],
      refreshToken: response["refresh_token"],
    }
    
    localStorage.setItem("info", JSON.stringify(info))
    yield put(loginSuccess(response))

    if (response["status"] === "00") {
      history.push("/dashboard")
    } else if (response["status"] === "101") {
      history.push("/reset-password")
    }

    // console.log(JSON.stringify(info));


  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put(logoutUserSuccess("Logout"))
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
