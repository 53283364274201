export const CREATE_USER = "CREATE_USER"
export const CREATE_SUCCESS = "CREATE_SUCCESS"

export const USERS = "USERS"
export const USERS_SUCCESS = "USERS_SUCCESS"

export const USERS_BY_ID = "USERS_BY_ID"
export const USERS_BY_ID_SUCCESS = "USERS_BY_ID_SUCCESS"


export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"


export const API_ERROR = "USERS_API_ERROR"