import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/Breadcrumb/actions"
import MetaTags from "react-meta-tags"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import logoDark from "../../assets/images/logo-dark.png"
import logoLightPng from "../../assets/images/logo-light.png"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { config } from "../../store/config/BaseUrl"

const ResetPassword = props => {

  // scoping
  // scrum master at the team
  // Allocate Task
  // Strong team
  // code review and confirming test
  //

  const [errMsg, setErrMsg] = useState("")
  const [errVisible, setErrVisible] = useState(false)
  const [successMsg, setSuccessMsg] = useState("")
  const [successVisible, setSuccessVisible] = useState(false)
  const[postData, setPostData] = useState({
    password: "",
    confirmPassword: "",
  })

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Reset Password", link: "/reset-password" },
  ]

  function postPasswordData(){

    if(postData.password !== postData.confirmPassword){
      setErrMsg("Password does not match")
      setErrVisible(true)
      return
    }

    axios
      .post(config.url.API_URL + "/auth/verify-token", postData)
      .then(res => {
        const response = res.data
        if (response['status'] === '00') {
          setErrVisible(false)
          setSuccessMsg(response['message'])
          setSuccessVisible(true)
          props.history.push("/dashboard")
        } else {
          setErrMsg(response['message']);
          window.setTimeout(() => { setErrVisible(true)}, 1)
          console.log(response)
        }
      })
      .catch(err => {
        // props.logoutUser(props.history)
        // if (null !== err.response.status && err.response.status === 401) {
        console.log("STATUS " + err)
        // }
      })
  }

  return(
    <React.Fragment>
      <MetaTags>
        <title>Login | Lexa - Responsive Bootstrap 5 Admin Dashboard</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="30" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Reset Password</h4>
                    <p className="text-muted text-center"></p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e) => {
                        // handleValidSubmit(e, v)
                        postPasswordData()
                      }}
                    >

                      <Alert
                        color="danger"
                        isOpen={successVisible}
                        toggle={e => setSuccessVisible(false)}>
                        {" "}
                        {successMsg}{" "}
                      </Alert>
                      <Alert
                        color="danger"
                        isOpen={errVisible}
                        toggle={e => setErrVisible(false)}>
                        {" "}
                        {errMsg}{" "}
                      </Alert>

                      <div className="mb-3">
                        <AvField
                          name="newPassword"
                          label="New Password"
                          value=""
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          required
                          onChange ={(e) => {
                            setErrVisible(false)
                            postData.password = e.target.value
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="confirmPassword"
                          label="confirmPassword"
                          type="password"
                          placeholder="Confrim Password"
                          required
                          onChange ={(e) => {
                            setErrVisible(false)
                            postData.confirmPassword = e.target.value
                          }}
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </div>
                      <div className="form-group mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                        </div>
                      </div>

                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="register"
                    className="text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Licensing server
                  <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Riverbank.</span>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

}

export default withRouter(connect(null, { setBreadcrumbItems })(ResetPassword))
