import {
  CREATE_USER,
  CREATE_SUCCESS,
  USERS,
  USERS_SUCCESS,
  USERS_BY_ID,
  USERS_BY_ID_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

import {
  createUser,
  createSuccess,
  users,
  usersSuccess,
  userById,
  userByIdSuccess,
  apiError,
} from "./action"

// AUTH related methods
import { getService } from "../DashboardService"

const restService = getService()

// function* createUser({ payload: user }) {}

function* fetchUsers() {
  try {
    const response = yield call(restService.fetchUsers, "/users")
    yield put(usersSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* UsersSaga() {
  // yield takeEvery(CREATE_USER, createUser())
  yield takeEvery(USERS, fetchUsers())
}

export default UsersSaga
