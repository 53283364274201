import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import {
    Table,
    UncontrolledTooltip
} from "reactstrap"
import axios from 'axios';
import { config } from 'store/config/BaseUrl';


class ActiveTerminalsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getActiveTerminals()
    }

    getActiveTerminals = () => {
        var url = "/terminals/"

        axios.get(config.url.API_URL + url
        ).then(res => {

            if ("00" === res.data.status) {

                let dta = res.data.data;
                if (!dta) {
                    return;
                }
                this.setState({ data: dta })
            }

        }).catch(err => {
            // props.logoutUser(props.history)
            this.setState({ data: [] });
            if (!err.response || !err.response.status) {
                return;
            }

            if (err.response.status === 401) {
                // console.log("STATUS " + err.response.status)
                this.props.history.push('/login');
            }
        })
    }

    render() {

        return (
            <React.Fragment>
                <div className="table-responsive mt-3">
                    <Table className="table-centered datatable dt-responsive nowrap " style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%"
                    }}>
                        <thead className="thead-light">
                            <tr>
                                <th>Serial Number</th>
                                <th>Model</th>
                                <th>Project</th>
                                <th>OS Type</th>
                                <th>OS Version</th>
                                <th style={{ width: "120px" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.data && this.state.data.map((config, key) => {


                                    return <tr key={key}>

                                        <td>{config.serialNumber}</td>
                                        <td>{config.name}</td>
                                        <td>{config.projectName}</td>
                                        <td>{config.configName}</td>
                                        <td>{config.osVersion}</td>
                                        {/* <td>
                                            {String(config.active)}
                                        </td> */}
                                        <td>
                                            <Link to="#" className="mr-3 text-primary" id={"edit" + key} onClick={() => {

                                            }}>
                                                <i className="mdi mdi-pencil font-size-18"></i></Link>
                                            <UncontrolledTooltip target={"edit" + key}
                                                placement="top">
                                                Edit
                                            </UncontrolledTooltip>
                                            <Link to="#" className="text-danger"
                                                id={"delete" + key} onClick={() => {
                                                    this.state.selConfig = config;
                                                }}> <i
                                                    className="mdi mdi-trash-can font-size-18"></i></Link>
                                            <UncontrolledTooltip target={"delete" + key}
                                                placement="top">
                                                Delete
                                            </UncontrolledTooltip>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ActiveTerminalsTab);