import { getConfig } from "@testing-library/react"
import axios from "axios"
import React, {  useEffect, useRef, useState } from "react"
import { Link, Redirect, useHistory, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
  Label,
  Modal,
  Button
} from "reactstrap"
import { config } from "store/config/BaseUrl"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import NewConfigModal from "pages/modal/NewConfigModal"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/Breadcrumb/actions"

const Configs = props => {
  const configModal = useRef()
  const [data, setData] = useState([])
  const [modalData, setModalData] = useState([])
  const [allConfigs, setAllConfigs] = useState([])
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const [showSweetAlert, setShowSweetAlert] = useState(false)
  const [confirm_alert, setConfirmAlert] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modal_standard, setModalStandard] = useState(false)
  const [config_modal, setConfigModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [types, setTypes] = useState(false)

  const [selConfig, setSelConfig] = useState({
    id: null,
    configId: 0,
    name: "",
    code: "",
  })

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Configs", link: "/configs" },
  ]

  const tog_standard = prevState => {

  }

  useEffect(() => {
    props.setBreadcrumbItems("Configs", breadcrumbItems)
    getAllConfigs(0)
  }, [])

  const getAllConfigs = type => {
    try {
      JSON.parse(localStorage.getItem("info"))
    } catch {
      console.log("Error")
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: this.props.location } }}
        />
      )
    }

    var url = "/configs/"
    if (type > 0) {
      url = url + type
      setTypes(true)
    } else {
      setTypes(false)
    }

    let fullURL = config.url.API_URL + url

    axios
      .get(fullURL)
      .then(res => {
        var configs = []
        if ("00" === res.data.status) {
          if (type <= 0) {
            let itemAll = { id: 0, name: "ALL", code: "code" }
            configs.push(itemAll)
            let dta = res.data.data["configs"]
            if (!dta) {
              return
            }
            for (let i = 0; i < dta.length; i++) {
              configs.push(dta[i])
            }
            setData(res.data.data["configTypes"])
          }
        }

        console.log(res.data)
        if (type <= 0) {
          setAllConfigs(configs)
        } else {
          setData(res.data.data)
        }
      })
      .catch(err => {
        // props.logoutUser(props.history)
        setData([])
        if (!err.response || !err.response.status) {
          return
        }

        if (err.response.status === 401) {
          // console.log("STATUS " + err.response.status)
          this.props.history.push("/login")
        }
      })
  }

  // setArray = data => {
  //   var arr = []
  //   for (let i = 0; i < data.length; i++) {
  //     arr.push(data[i])
  //   }
  //   return arr
  // }
  //
  // setValue = value => {
  //   this.setState(prevState => ({
  //     select: {
  //       ...prevState.select,
  //       value,
  //     },
  //   }))
  // }
  //
  // handleChange = e => {
  //   var value = e.target.value
  //   this.getAllConfigs(value)
  //   e.preventDefault()
  // }
  //

  //
  // setsuccess_msg = type => {
  //   window.setTimeout(() => {
  //     this.setState({ showSweetAlert: false })
  //   }, 1)
  // }
  //

  const handleCallback = childData => {
      if (childData) {
        window.setTimeout(() => {
          setShowSweetAlert(true)
        }, 1)
        tog_standard()
      }
  }
  // handleCallback = childData => {
  //   if (childData) {
  //     window.setTimeout(() => {
  //       this.setState({ showSweetAlert: true })
  //     }, 1)
  //     this.tog_modal()
  //   }
  // }

  return (
    <React.Fragment>
      {showSweetAlert ? (
        <SweetAlert
          title={setdynamic_title}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }} >
          {setdynamic_description}
        </SweetAlert>
      ) : null}

      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          confirmButtonText="Yes, delete it!"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
          }}
          onCancel={() => setconfirm_alert(false)} >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Modal isOpen={modal_standard} toggle={tog_standard}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            New project
          </h5>
        </div>
        <div className="modal-body">
          <NewConfigModal
            ref={configModal}
            parentCallback={handleCallback}
            selConfig={selConfig}
            configCat={allConfigs} />
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => setModalStandard(false)}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal" >
            Close
          </button>
          <button
            onClick={()=> configModal.current.postData()}
            type="button"
            className="btn btn-primary waves-effect waves-light" >
            Save changes
          </button>
        </div>
      </Modal>
      <div>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="button-items mb-1 col-3">
                  {/* <div className="button-items mb-1"> */}
                  <select
                    name="configs"
                    // onChange={this.handleChange}
                    className="col-6"
                  >
                    {allConfigs.map((e, key) => {
                      return (
                        <option className="pl-1" key={key} value={e.id}>
                          {" "}
                          {e.name}{" "}
                        </option>
                      )
                    })}
                  </select>

                  <div className="col-6">
                    <Button
                      type="button"
                      onClick={() => setModalStandard(!false)}
                      color="primary"
                      className="btn btn-success mb-2 "
                    >
                      Add Config
                    </Button>
                  </div>
                  {/* </div> */}
                </Row>

                <div className="table-responsive mt-3">
                  <Table
                    className="table-centered datatable dt-responsive nowrap "
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: 0,
                      width: "100%",
                    }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "20px" }}>
                          <div className="custom-control custom-checkbox">
                            <Input
                              type="checkbox"
                              className="custom-control-input"
                              id="customercheck"
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor="customercheck"
                            >
                              &nbsp;
                            </Label>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Name</th>
                        {types && <th>Code</th>}
                        <th>Active</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.map((config, key) => {
                          if (config.id <= 0) {
                            return
                          }

                          return (
                            <tr key={key}>
                              <td>
                                <div className="ustom-control custom-checkbox">
                                  <Input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={"customercheck" + key}
                                  />
                                  <Label
                                    className="custom-control-label"
                                    htmlFor={"customercheck" + key}
                                  >
                                    &nbsp;
                                  </Label>
                                </div>
                              </td>

                              <td>{config.id}</td>
                              <td>{config.name}</td>
                              {types && <td>{config.code}</td>}
                              <td>{String(config.active)}</td>
                              <td>
                                <Link
                                  to="#"
                                  className="mr-3 text-primary"
                                  id={"edit" + key}
                                  onClick={() => {}}
                                >
                                  <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                                <UncontrolledTooltip
                                  target={"edit" + key}
                                  placement="top"
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Link
                                  to="#"
                                  className="text-danger"
                                  id={"delete" + key}
                                  onClick={() => {
                                    // selConfig = config
                                  }}
                                >
                                  {" "}
                                  <i className="mdi mdi-trash-can font-size-18"></i>
                                </Link>
                                <UncontrolledTooltip
                                  target={"delete" + key}
                                  placement="top"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(connect(null, { setBreadcrumbItems })(Configs))
